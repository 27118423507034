import { Controller } from 'stimulus'
import numeral from 'numeral'
import 'numeral/locales'
import VMasker from 'vanilla-masker'

const CPF_LENGTH = 11

export default class extends Controller {
  static targets = ['input', 'hiddenInput']

  initialize() {
    numeral.locale('pt-BR')
  }

  connect() {
    var value = this.hiddenInputTargets.length ? this.hiddenInputTarget.value : this.inputTarget.value
    if (['oneDecimal', 'carbohydrate', 'sodium'].includes(this.data.get('type'))) return this.inputTarget.value = value
    this.inputTarget.value = this.parse(value)
  }

  parse(value) {
    const type = this.data.get('type')
    const parsers = {
      positive: this.toPositive(value),
      currency: this.toFloat(value, '0,0.00'),
      weight: this.toFloat(value, '0,0.0(00)'),
      limitedWeight: this.toFloat(value, '0,0.0(00)'),
      length: this.toFloat(value, '0,0.0(0)'),
      percentage: this.toPercentage(value),
      slug: this.toSlug(value),
      downcase: this.toDowncase(value),
      zipcode: this.toCustom(value, '99999-999'),
      cpf: this.toCustom(value, '999.999.999-99'),
      cnpj: this.toCustom(value, '99.999.999/9999-99'),
      phoneNumber: this.toCustom(value, '(99) 9999-99999'),
      dateTime: this.toDatetime(value, '99/99/9999 - 99:99'),
      dateFormat: this.toDateFormat(value, '99/99/9999'),
      ncm: this.toCustom(value, '9999.99.99'),
      cest: this.toCustom(value, '99.999.99'),
      oneDecimal: this.toCustom(value, '99.9'),
      carbohydrate: this.toCustom(value, '999.9'),
      sodium: this.toCustom(value, '9999.9'),
      calories: this.toCustom(value, '9999'),
      integer_part: this.toCustom(value, '99'),
      serving_size: this.toCustom(value, '999')
    }

    if (type !== 'cpf-cnpj') return parsers[type]
    if (this.unmask(value).length > CPF_LENGTH) return parsers['cnpj']
    return parsers['cpf']
  }

  toFloat(value, format) {
    value = value.replace('.', ',')
    return numeral(value).format(format)
  }

  toDateFormat(value, format) {
    if (this.data.get('type') != 'dateFormat') return

    if (value.length < 10) return VMasker.toPattern(value, format)
    let [day, month, year] = value.split('/')

    // force a valid datetime before parse
    day = day > 31 ? 31 : day
    month = month > 12 ? 12 : month

    value = [day, month, year].join('')

    return VMasker.toPattern(value, format)
  }

  toDatetime(value, format) {
    if (this.data.get('type') != 'dateTime') return

    if (value.length < 18) return VMasker.toPattern(value, format)

    let [full_date, full_hour] = value.replace(' ', '').split('-')
    let [hour, minutes] = full_hour.split(':')
    let [day, month, year] = full_date.split('/')

    // force a valid datetime before parse
    day = day > 31 ? 31 : day
    month = month > 12 ? 12 : month

    hour = hour > 23 ? 23 : hour
    minutes = minutes > 59 ? 59 : minutes

    value = [day, month, year, hour, minutes].join('')

    return VMasker.toPattern(value, format)
  }

  toPercentage(value) {
    return VMasker.toMoney(value, {
      precision: 2,
      separator: '.',
      delimiter: ',',
      suffixUnit: '%',
    })
  }

  toSlug(value) {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/([^\w]+|\s+)/g, '-') // Remove space
      .replace(/(^-+)/, '') // Remove leading hifen
  }

  toPositive(value) {
    const newValue = parseInt(value.replace(/-/g, ''))
    if (isNaN(newValue)) return 0

    return newValue
  }

  toDowncase(value) {
    return value.toLowerCase()
  }

  toCustom(value, pattern) {
    return VMasker.toPattern(value, pattern)
  }

  getInputedValue(value) {
    return numeral(value).value()
  }

  getValidValue(value, exponent, slice_size) {
    let number = value.replace(/\D/g, '')
    if(slice_size) { number = number.slice(0, slice_size) }

    const power = Math.pow(10, exponent)

    return Number(number / power).toFixed(exponent)
  }

  getPercentageValue(value) {
    return value.replace(' %', '') // removed % char
  }

  unmask(value) {
    return value.replace(/\D/g, '')
  }

  unmaskDate(value, type) {
    if(type === 'dateTime'){
      // 99/99/9999 - 99:99 -> 18
      if (value.length < 18) return ''

      let [date, hour] = value.replace(' ', '').split('-')
      const date_numbers = date.match(/\d+/g)
      const hour_numbers = hour.match(/\d+/g)

      date = new Date(date_numbers[2], date_numbers[1] - 1, date_numbers[0], hour_numbers[0], hour_numbers[1])

      return date
    }else if(type === 'dateFormat') {
      if(value.length == 10){
        const p = value.match(/\d+/g)
        return new Date(Number.parseInt(p[2]), Number.parseInt(p[1] - 1), Number.parseInt(p[0]))
      }
      return 'Invalid date'
    }
    return ''
  }

  format({ target }) {
    const { value } = target
    const type = this.data.get('type')

    switch (type) {
      case 'length':
        this.inputTarget.value = this.parse(this.getValidValue(value, 2, 5))
        this.hiddenInputTarget.value = this.getInputedValue(this.inputTarget.value)
        break
      case 'positive':
        this.inputTarget.value = this.toPositive(value)
        this.hiddenInputTarget.value = this.toPositive(value)
        break
      case 'currency':
        this.inputTarget.value = this.parse(this.getValidValue(value, 2))
        this.hiddenInputTarget.value = this.getInputedValue(this.inputTarget.value)
        break
      case 'weight':
        this.inputTarget.value = this.parse(this.getValidValue(value, 3))
        this.hiddenInputTarget.value = this.getInputedValue(this.inputTarget.value)
        break
      case 'limitedWeight':
        this.inputTarget.value = this.parse(this.getValidValue(value, 3, 5))
        this.hiddenInputTarget.value = this.getInputedValue(this.inputTarget.value)
        break
      case 'percentage':
        this.inputTarget.value = this.parse(value)
        this.hiddenInputTarget.value = this.getPercentageValue(this.inputTarget.value)
        break
      case 'cpf':
      case 'cnpj':
      case 'cpf-cnpj':
      case 'phoneNumber':
      case 'ncm':
      case 'cest':
        this.inputTarget.value = this.parse(value)
        this.hiddenInputTarget.value = this.unmask(this.inputTarget.value)
        break
      case 'dateTime':
      case 'dateFormat':
        this.inputTarget.value = this.parse(value)
        this.hiddenInputTarget.value = this.unmaskDate(this.inputTarget.value, type)
        break
      case 'oneDecimal':
        this.inputTarget.value = this.parse(this.getValidValue(value, 2))
        this.hiddenInputTarget.value = this.inputTarget.value
        break
      case 'carbohydrate':
        this.inputTarget.value = this.parse(this.getValidValue(value, 3))
        this.hiddenInputTarget.value = this.inputTarget.value
        break
      case 'sodium':
        this.inputTarget.value = this.parse(this.getValidValue(value, 4))
        this.hiddenInputTarget.value = this.inputTarget.value
        break
      case 'integer_part':
      case 'serving_size':
      case 'calories':
        this.inputTarget.value = this.parse(value)
        this.hiddenInputTarget.value = this.inputTarget.value
        break
      default:
        this.inputTarget.value = this.parse(value)
    }
  }

  clearInputBasedOnLength(event) {
    const type = this.data.get('type')
    const typesMinLength = {
      dateTime: 18,
      ncm: 10,
      cest: 9,
    }

    if (event.target.value.length < typesMinLength[type]) event.target.value = ''
  }
}
