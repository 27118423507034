import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["row", "currentQtd", "countedQtd", "adjustedQtd", "itemFound", "reservedQtd", "inTransitQtd", "reservedError", "inTransitError"]

  handleChange () {
    const currentQtd = Number(this.currentQtdTarget.textContent)
    const countedQtd = Number(this.countedQtdTarget.value)
    const initialValue = Number(this.data.get('countedInitialValue'))
    const hasChanged = countedQtd !== initialValue

    this.adjustedQtdTarget.value = (countedQtd - currentQtd)
    this.verifyReservedQtd()
    this.toggleSubmitUpdate(hasChanged)
  }

  toggleSubmitUpdate(hasChanged) {
    const finishBtn = this.finishBtn()
    const updateBtn = this.updateBtn()

    if (hasChanged) {
      finishBtn.hidden = true
      updateBtn.hidden = false
    } else {
      this.verifyAllCountedInputs()
    }
  }

  verifyReservedQtd() {
    const reservedQtd = Number(this.reservedQtdTarget.textContent) || 0
    const countedQtd = Number(this.countedQtdTarget.value) || 0
    const inTransitQtd = Number(this.inTransitQtdTarget.textContent) || 0

    const isInvalid = reservedQtd + inTransitQtd > countedQtd
    const hiddenReservedError = reservedQtd !== 0 && isInvalid
    const hiddenInTransitError = inTransitQtd !== 0 && isInvalid

    this.rowTarget.classList.toggle('ch-table-row--negative', isInvalid)
    this.reservedErrorTarget.hidden = !hiddenReservedError
    this.inTransitErrorTarget.hidden = !hiddenInTransitError
  }

  verifyAllCountedInputs() {
    let anyChanged = Array.prototype.filter.call(this.getCountedQtdInputs(), e => {
      if (Number(e.dataset.initialValue) !== Number(e.value)) return e
    })

    if (anyChanged.length === 0) {
      this.finishBtn().hidden = false
      this.updateBtn().hidden = true
    }
  }

  finishBtn() {
    return document.querySelector('#inventoryFinish')
  }

  updateBtn() {
    return document.querySelector('#inventoryUpdate')
  }

  getCountedQtdInputs() {
    return document.querySelectorAll("[data-target='inventory-adjustment.countedQtd']")
  }
}
